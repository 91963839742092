// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aapenhetsloven-tsx": () => import("./../../../src/pages/aapenhetsloven.tsx" /* webpackChunkName: "component---src-pages-aapenhetsloven-tsx" */),
  "component---src-pages-digitalarbeidsflate-tsx": () => import("./../../../src/pages/digitalarbeidsflate.tsx" /* webpackChunkName: "component---src-pages-digitalarbeidsflate-tsx" */),
  "component---src-pages-forretningsforsel-tsx": () => import("./../../../src/pages/forretningsforsel.tsx" /* webpackChunkName: "component---src-pages-forretningsforsel-tsx" */),
  "component---src-pages-forsikringogskadevern-pensjonforsikring-tsx": () => import("./../../../src/pages/forsikringogskadevern/pensjonforsikring.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-pensjonforsikring-tsx" */),
  "component---src-pages-forsikringogskadevern-sikring-mot-brann-brannvernkurs-tsx": () => import("./../../../src/pages/forsikringogskadevern/sikring-mot-brann/brannvernkurs.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-sikring-mot-brann-brannvernkurs-tsx" */),
  "component---src-pages-forsikringogskadevern-sikring-mot-brann-tsx": () => import("./../../../src/pages/forsikringogskadevern/sikring-mot-brann.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-sikring-mot-brann-tsx" */),
  "component---src-pages-forsikringogskadevern-sikring-mot-vann-tsx": () => import("./../../../src/pages/forsikringogskadevern/sikring-mot-vann.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-sikring-mot-vann-tsx" */),
  "component---src-pages-forsikringogskadevern-skadevern-tsx": () => import("./../../../src/pages/forsikringogskadevern/skadevern.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-skadevern-tsx" */),
  "component---src-pages-forsikringogskadevern-tipsograd-sjekkliste-tsx": () => import("./../../../src/pages/forsikringogskadevern/tipsograd/sjekkliste.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-tipsograd-sjekkliste-tsx" */),
  "component---src-pages-forsikringogskadevern-tipsograd-tips-1-tsx": () => import("./../../../src/pages/forsikringogskadevern/tipsograd/tips1.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-tipsograd-tips-1-tsx" */),
  "component---src-pages-forsikringogskadevern-tipsograd-tsx": () => import("./../../../src/pages/forsikringogskadevern/tipsograd.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-tipsograd-tsx" */),
  "component---src-pages-forsikringogskadevern-totalforsikring-tsx": () => import("./../../../src/pages/forsikringogskadevern/totalforsikring.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-totalforsikring-tsx" */),
  "component---src-pages-forsikringogskadevern-tsx": () => import("./../../../src/pages/forsikringogskadevern.tsx" /* webpackChunkName: "component---src-pages-forsikringogskadevern-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-medlemsfordeler-tsx": () => import("./../../../src/pages/medlemsfordeler.tsx" /* webpackChunkName: "component---src-pages-medlemsfordeler-tsx" */),
  "component---src-pages-miljofyrtarn-tsx": () => import("./../../../src/pages/miljofyrtarn.tsx" /* webpackChunkName: "component---src-pages-miljofyrtarn-tsx" */),
  "component---src-pages-personvernerklaering-tsx": () => import("./../../../src/pages/personvernerklaering.tsx" /* webpackChunkName: "component---src-pages-personvernerklaering-tsx" */),
  "component---src-pages-pressemelding-tsx": () => import("./../../../src/pages/pressemelding.tsx" /* webpackChunkName: "component---src-pages-pressemelding-tsx" */),
  "component---src-pages-slett-din-bruker-tsx": () => import("./../../../src/pages/slett-din-bruker.tsx" /* webpackChunkName: "component---src-pages-slett-din-bruker-tsx" */)
}

